import { config, isLocal } from 'app-config';
import Head from 'next/head';
import React, { Fragment } from 'react';
import { VARIANTS } from 'fw-utils/CONSTANTS';
import appsignal from 'app-utils/appsignal';
import FormwizardChooser from '../../formwizard/src';
import useFormwizardHeightChange from '../../hooks/useFormwizardHeightChange';
import useLeadId from '../../hooks/useLeadId';
import useRecruiterId from '../../hooks/useRecruiterId';
import useUrlParams from '../../hooks/useUrlParams';
import useTracking from '../../hooks/useTracking';

const Base = () => {
  const { dialogId } = useUrlParams();
  const leadId = useLeadId();
  const recruiterId = useRecruiterId();
  const doTrack = useTracking();

  // Get height from `.main`. We can't take the height from `.layout`
  // because it's always 100% of the viewport, which can be less than the content inside of it.
  //
  // We want the raw value of the content itself
  const { ref } = useFormwizardHeightChange(null, 0);

  return (
    <Fragment>
      <Head>
        <title>Formwizard HAUSGOLD</title>
      </Head>
      <FormwizardChooser
        appsignal={appsignal}
        variant={VARIANTS.DEFAULT}
        leadId={leadId}
        recruiterId={recruiterId}
        dialogId={dialogId}
        isLocal={isLocal}
        ref={ref}
        config={config}
        doTrack={doTrack}
      />
    </Fragment>
  );
};

export default Base;
